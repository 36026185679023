export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoTabs = {
  roulettetab: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      RouletteFirstPerson: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
      // AutoRoulettePargmatic: {
      //   redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
      //   code: "vs20stickysymbol",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
      //   alt: "",
      //   name: "Auto Roulette Pragmatic",
      //   providerName: "Pragmatic Play",
      // },
      // Roulette1Azure: {
      //   redirectUrl: "/casino/ezugi/Roulette1Azure",
      //   code: "227",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Roulette1Azure.jpg",
      //   alt: "",
      //   name: "Roulette Azure",
      //   providerName: "Pragmatic Play",
      // },
      // RouletteLobby: {
      //   redirectUrl: "/casino/ezugi/RouletteLobby",
      //   code: "102",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/roulletewheel.jpg",
      //   alt: "",
      //   name: "Roulette Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    // slide3: {
    //   AutoRoulettePargmatic: {
    //     redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
    //     code: "225",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
    //     alt: "",
    //     name: "Auto Roulette Pragmatic",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette1Azure: {
    //     redirectUrl: "/casino/ezugi/Roulette1Azure",
    //     code: "227",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette1Azure.jpg",
    //     alt: "",
    //     name: "Roulette Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteLobby: {
    //     redirectUrl: "/casino/ezugi/RouletteLobby",
    //     code: "102",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulletewheel.jpg",
    //     alt: "",
    //     name: "Roulette Lobby",
    //     providerName: "Pragmatic Play",
    //   },
    //   PragmaticTurkishRoulette: {
    //     redirectUrl: "/casino/ezugi/PragmaticTurkishRoulette",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/pragturkish.jpg",
    //     alt: "",
    //     name: "Turkish Roulette ",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulettegerman: {
    //     redirectUrl: "/casino/ezugi/roulettegerman",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulettegerman.jpg",
    //     alt: "",
    //     name: "Roulette German",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteRussian: {
    //     redirectUrl: "/casino/ezugi/RouletteRussian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette-Russian.jpg",
    //     alt: "",
    //     name: "Roulette Russian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette3Macao: {
    //     redirectUrl: "/casino/ezugi/Roulette3Macao",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette3Macao.jpg",
    //     alt: "",
    //     name: "Roulette Macao",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette9TheClub: {
    //     redirectUrl: "/casino/ezugi/Roulette9TheClub",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette9TheClub.jpg",
    //     alt: "",
    //     name: "Roulette The Club",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulette7italian: {
    //     redirectUrl: "/casino/ezugi/roulette7italian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulette7italian.jpg",
    //     alt: "",
    //     name: "Roulette 7 Italian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette10Ruby: {
    //     redirectUrl: "/casino/ezugi/Roulette10Ruby",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette10Ruby.jpg",
    //     alt: "",
    //     name: "Roulette 10 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Roulette",
  },

  baccarattab: {
    slide1: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },

      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },
      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide2: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },
      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    // slide3: {
    //   NoCommSpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/NoCommSpeedBaccarat1",
    //     code: "436",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/NoCommSpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "No Commission Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   SpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/SpeedBaccarat1",
    //     code: "566",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/SpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "Speed Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   // speedbaccarat5: {
    //   //   redirectUrl: "/casino/ezugi/speedbaccarat5",
    //   //   code: "570",
    //   //   casino: "ezugi",
    //   //   provider: "",
    //   //   homeUrl: pageUrlGenerator("/Casino"),
    //   //   imgUrl: "./images/roulette/speedbaccarat5.jpg",
    //   //   alt: "",
    //   //   name: "Speed Baccarat 5",
    //   //   providerName: "Pragmatic Play",
    //   // },
    // },
    name: "Baccarat",
  },

  Blackjacktab: {
    slide1: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },
      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      // TurkceBlackjack: {
      //   redirectUrl: "/casino/ezugi/TurkceBlackjack",
      //   code: "501",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
      //   alt: "",
      //   name: "Turkce Blackjack 3",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      // FreebetBlackjack: {
      //   redirectUrl: "/casino/ezugi/FreebetBlackjack",
      //   code: "1000066",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
      //   alt: "",
      //   name: "Freebet Blackjack",
      //   providerName: "Evolution",
      // },
      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack1: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack1",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide2: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },
      // ClubBlackjack: {
      //   redirectUrl: "/casino/ezugi/ClubBlackjack",
      //   code: "502",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Club-Blackjack.jpg",
      //   alt: "",
      //   name: "Club Blackjack",
      //   providerName: "Ezugi",
      // },
      // Rumbablackjacks2: {
      //   redirectUrl: "/casino/ezugi/Rumbablackjacks2",
      //   code: "412",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 2",
      //   providerName: "Ezugi",
      // },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      // AllStarsBlackjackGold1: {
      //   redirectUrl: "/casino/ezugi/AllStarsBlackjackGold1",
      //   code: "504",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
      //   alt: "",
      //   name: "All Stars Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },
    // slide3: {
    //   Blackjack17Azure: {
    //     redirectUrl: "/casino/ezugi/Blackjack17Azure",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack17Azure.jpg",
    //     alt: "",
    //     name: "Blackjack 17 Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   GoldBlackjack: {
    //     redirectUrl: "/casino/ezugi/GoldBlackjack",
    //     code: "1",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack02.jpg",
    //     alt: "",
    //     name: "Blackjack Gold 5",
    //     providerName: "Ezugi",
    //   },
    //   Blackjack39Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack39Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack39Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 39 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack42Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack42Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack42Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 42 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   UnlimitedBlackjack: {
    //     redirectUrl: "/casino/ezugi/UnlimitedBlackjack",
    //     code: "51",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Unlimited-Blackjack.jpg",
    //     alt: "",
    //     name: "Unlimited Blackjack",
    //     providerName: "Ezugi",
    //   },

    //   BlackjackVIPO: {
    //     redirectUrl: "/casino/ezugi/BlackjackVIPO",
    //     code: "1000058",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack-VIP-O.jpg",
    //     alt: "",
    //     name: "Blackjack VIP O",
    //     providerName: "Evolution",
    //   },
    //   Blackjack11: {
    //     redirectUrl: "/casino/ezugi/Blackjack11",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack11.jpg",
    //     alt: "",
    //     name: "Blackjack 11",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack14: {
    //     redirectUrl: "/casino/ezugi/Blackjack14",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack14.jpg",
    //     alt: "",
    //     name: "Blackjack 14",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack16: {
    //     redirectUrl: "/casino/ezugi/Blackjack16",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack16.jpg",
    //     alt: "",
    //     name: "Blackjack 16",
    //     providerName: "Pragmatic Play",
    //   },
    //   OneBlackjack: {
    //     redirectUrl: "/casino/ezugi/OneBlackjack",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/OneBlackjack.jpg",
    //     alt: "",
    //     name: "One Blackjack",
    //     providerName: "Pragmatic Play",
    //   },
    //   ONEBlackjack2: {
    //     redirectUrl: "/casino/ezugi/ONEBlackjack2",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/ONEBlackjack2.jpg",
    //     alt: "",
    //     name: "One Blackjack 2",
    //     providerName: "Pragmatic Play",
    //   },
    //   SpeedBlackjack3Ruby: {
    //     redirectUrl: "/casino/ezugi/SpeedBlackjack3Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/SpeedBlackjack3Ruby.jpg",
    //     alt: "",
    //     name: "Speed Blackjack 3 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Blackjack",
  },

  pokertab: {
    slide1: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti1: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti1",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    name: "Poker",
  },

  spribe: {
    slide1: {
      aviator: {
        redirectUrl: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/aviator.gif",
        alt: "",
        name: "Aviator",
        providerName: "Spribe",
      },
      mines: {
        redirectUrl: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mines.jpg",
        alt: "",
        name: "Mines",
        providerName: "Spribe",
      },
      goal: {
        redirectUrl: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Goal.jpg",
        alt: "",
        name: "Goal",
        providerName: "Spribe",
      },
      dice: {
        redirectUrl: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Dice.jpg",
        alt: "",
        name: "Dice",
        providerName: "Spribe",
      },
      plinko: {
        redirectUrl: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Plinko.jpg",
        alt: "",
        name: "Plinko",
        providerName: "Spribe",
      },
      miniroulette: {
        redirectUrl: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mini-roulette.jpg",
        alt: "",
        name: "Mini Roulette",
        providerName: "Spribe",
      },
      hilo: {
        redirectUrl: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/HIlo.jpg",
        alt: "",
        name: "Hilo",
        providerName: "Spribe",
      },
    },
    name: "Spribe",
  },

  Vivo: {
    slide1: {
      CoffeeWild: {
        redirectUrl: "/casino/vivo-dragon-tiger",
        imgUrl: "./images/Vivo/DragonTiger.jpg",
        name: "Dragon Tiger",
        providerName: "Vivo",
      },
      EuropeanAutoRoulette: {
        redirectUrl: "/casino/vivo-european-auto-roulette",
        imgUrl: "./images/Vivo/EuropeanAutoRoulette.jpg",
        name: "European Auto Roulette",
        providerName: "Vivo",
      },
      FrenchRoulette: {
        redirectUrl: "/casino/vivo-french-roulette",
        imgUrl: "./images/Vivo/FrenchRoulette.jpg",
        name: "French Roulette",
        providerName: "Vivo",
      },
      GalaxyBaccarat1: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-1",
        imgUrl: "./images/Vivo/GalaxyBaccarat1.jpg",
        name: "Galaxy Baccarat1",
        providerName: "Vivo",
      },
      GalaxyBaccarat2: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-2",
        imgUrl: "./images/Vivo/GalaxyBaccarat2.jpg",
        name: "Galaxy Baccarat 2",
        providerName: "Vivo",
      },
      GalaxyBaccarat3: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-3",
        imgUrl: "./images/Vivo/GalaxyBaccarat3.jpg",
        name: "Galaxy Baccarat 3",
        providerName: "Vivo",
      },
      GalaxyRoulette: {
        redirectUrl: "/casino/vivo-galaxy-roulette",
        imgUrl: "./images/Vivo/GalaxyRoulette.jpg",
        name: "Galaxy Roulette",
        providerName: "Vivo",
      },
      MacauBaccarat: {
        redirectUrl: "/casino/vivo-macau-baccarat",
        imgUrl: "./images/Vivo/MacauBaccarat.jpg",
        name: "Macau Baccarat",
        providerName: "Vivo",
      },
      Oracle360Roulette: {
        redirectUrl: "/casino/vivo-oracle-360-roulette",
        imgUrl: "./images/Vivo/Oracle360Roulette.jpg",
        name: "Oracle 360 Roulette",
        providerName: "Vivo",
      },
      OracleBlazeRoulette: {
        redirectUrl: "/casino/vivo-oracle-blaze-roulette",
        imgUrl: "./images/Vivo/OracleBlazeRoulette.jpg",
        name: "Oracle Blaze Roulette",
        providerName: "Vivo",
      },
      PortomasoRoulette: {
        redirectUrl: "/casino/vivo-portomaso-roulette",
        imgUrl: "./images/Vivo/PortomasoRoulette.jpg",
        name: "Portomaso Roulette",
        providerName: "Vivo",
      },
      TeenPatti: {
        redirectUrl: "/casino/vivo-teen-patti",
        imgUrl: "./images/Vivo/TeenPatti.jpg",
        name: "Teen Patti",
        providerName: "Vivo",
      },
    },
    slide2: {
      VABacarat1: {
        redirectUrl: "/casino/vivo-va-bacarat-1",
        imgUrl: "./images/Vivo/VABacarat1.jpg",
        name: "VA Bacarat 1",
        providerName: "Vivo",
      },
      VABacarat2: {
        redirectUrl: "/casino/vivo-va-bacarat-2",
        imgUrl: "./images/Vivo/VABacarat2.jpg",
        name: "VA Bacarat 2",
        providerName: "Vivo",
      },
      VABacarat3: {
        redirectUrl: "/casino/vivo-va-bacarat-3",
        imgUrl: "./images/Vivo/VABacarat3.jpg",
        name: "VA Bacarat 3",
        providerName: "Vivo",
      },
      VABacarat4: {
        redirectUrl: "/casino/vivo-va-bacarat-4",
        imgUrl: "./images/Vivo/VABacarat4.jpg",
        name: "VA Bacarat 4",
        providerName: "Vivo",
      },
      VABacarat5: {
        redirectUrl: "/casino/vivo-va-bacarat-5",
        imgUrl: "./images/Vivo/VABacarat5.jpg",
        name: "VA Bacarat 5",
        providerName: "Vivo",
      },
    },
    name: "Vivo",
  },

  TvShows: {
    slide1: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },
      // GonzosTreasureHunt: {
      //   redirectUrl: "/casino/ezugi/GonzosTreasureHunt",
      //   code: "1100022",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/tvshows/GonzosTreasureHunt.jpg",
      //   alt: "",
      //   name: "Gonzo's Treasure Hunt",
      //   providerName: "Evolution",
      // },
      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },
    name: "TV Games",
  },

  AllGames: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
    },
    slide3: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },

      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide4: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    slide5: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },

      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      // TurkceBlackjack: {
      //   redirectUrl: "/casino/ezugi/TurkceBlackjack",
      //   code: "501",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
      //   alt: "",
      //   name: "Turkce Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      // FreebetBlackjack: {
      //   redirectUrl: "/casino/ezugi/FreebetBlackjack",
      //   code: "1000066",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
      //   alt: "",
      //   name: "Freebet Blackjack",
      //   providerName: "Evolution",
      // },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide6: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      // ClubBlackjack: {
      //   redirectUrl: "/casino/ezugi/ClubBlackjack",
      //   code: "502",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Club-Blackjack.jpg",
      //   alt: "",
      //   name: "Club Blackjack",
      //   providerName: "Ezugi",
      // },
      RumbaBlackjack2: {
        redirectUrl: "/casino/ezugi/RumbaBlackjack2",
        code: "412",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
        alt: "",
        name: "Rumba Blackjack 2",
        providerName: "Ezugi",
      },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      AllStarsBlackjackGold: {
        redirectUrl: "/casino/ezugi/AllStarsBlackjackGold",
        code: "504",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
        alt: "",
        name: "All Stars Blackjack",
        providerName: "Ezugi",
      },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },
    slide7: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti",
        code: "227102",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    slide8: {
      aviator: {
        redirectUrl: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Aviator1.jpg",
        alt: "",
        name: "Aviator",
        providerName: "Spribe",
      },
      mines: {
        redirectUrl: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mines.jpg",
        alt: "",
        name: "Mines",
        providerName: "Spribe",
      },
      goal: {
        redirectUrl: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Goal.jpg",
        alt: "",
        name: "Goal",
        providerName: "Spribe",
      },
      dice: {
        redirectUrl: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Dice.jpg",
        alt: "",
        name: "Dice",
        providerName: "Spribe",
      },
      plinko: {
        redirectUrl: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Plinko.jpg",
        alt: "",
        name: "Plinko",
        providerName: "Spribe",
      },
      miniroulette: {
        redirectUrl: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mini-roulette.jpg",
        alt: "",
        name: "Mini Roulette",
        providerName: "Spribe",
      },
      hilo: {
        redirectUrl: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/HIlo.jpg",
        alt: "",
        name: "Hilo",
        providerName: "Spribe",
      },
    },
    slide9: {
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },
      // GonzosTreasureHunt: {
      //   redirectUrl: "/casino/ezugi/GonzosTreasureHunt",
      //   code: "1100022",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/tvshows/GonzosTreasureHunt.jpg",
      //   alt: "",
      //   name: "Gonzo's Treasure Hunt",
      //   providerName: "Evolution",
      // },
      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
    },
    name: "All Games",
  },

  TrendingGames: {
    Aviator1: {
      redirectUrl: "/casino/spribe/aviator",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/aviator.jpg",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    AutoRoulette1: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/AutoRoulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    CrazyTime1: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/CrazyTime.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    dragontiger1: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/dragontiger.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    dreamcatcher1: {
      redirectUrl: "/casino/ezgevo-dream-catcher",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/dreamcatcher.jpg",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    // goasandarbahar1: {
    //   redirectUrl: "/casino/sn-goas-andar-bahar",
    //   code: "1000104",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/Casino"),
    //   imgUrl: "./images/trendinggames/goasandarbahar.jpg",
    //   alt: "",
    //   name: "Goa's Andar Bahar",
    //   providerName: "Supernowa",
    // },
    lightningRoulette1: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/lightningRoulette.jpg",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    Lucky71: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/Lucky7.jpg",
      alt: "",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    Mines1: {
      redirectUrl: "/casino/spribe/mines",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/Mines.jpg",
      alt: "",
      name: "Mines",
      providerName: "Spribe",
    },
    miniroulette1: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/miniroulette.jpg",
      alt: "",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
    NamasteRoulette1: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/NamasteRoulette.jpg",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    sicbo1: {
      redirectUrl: "/casino/ezg-sic-bo",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/sicbo.jpg",
      alt: "",
      name: "Sic Bo",
      providerName: "Ezugi",
    },
    SpeedRouletteEvo1: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/SpeedRoulette-Evo.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    SpeedRouletteEzu1: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/SpeedRoulette-ezugi.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    StockMarket1: {
      redirectUrl: "/casino/ezgevo-stock-market",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/stockmarket.jpg",
      alt: "",
      name: "	Stock Market",
      providerName: "Evolution",
    },
    SuperAndarBahar1: {
      redirectUrl: "/casino/evo-super-andar-bahar",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/SuperAndarBahar.jpg",
      alt: "",
      name: "	Super Andar Bahar",
      providerName: "Evolution",
    },
    // teenpattifaceoff1: {
    //   redirectUrl: "/casino/sn-teen-patti-face-off",
    //   code: "1000104",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/Casino"),
    //   imgUrl: "./images/trendinggames/teenpattifaceoff.jpg",
    //   alt: "",
    //   name: "Teen Patti Face Off",
    //   providerName: "Supernowa",
    // },
    // teenpattisupernowa1: {
    //   redirectUrl: "/casino/sn-teen-patti-2020",
    //   code: "1000104",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/Casino"),
    //   imgUrl: "./images/trendinggames/teenpattisupernowa.jpg",
    //   alt: "",
    //   name: "Teen Patti",
    //   providerName: "Supernowa",
    // },
    TurckeLightningRoulette1: {
      redirectUrl: "/casino/ezgevo-turkish-lightning-roulette",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/TurckeLightningRoulette.jpg",
      alt: "",
      name: "Turcke Lightning Roulette",
      providerName: "Evolution",
    },
    ultimateandarbahar1: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/ultimateandarbahar.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    xxtremeRoulette1: {
      redirectUrl: "/casino/ezgevo-xxxtreme-lightning-roulette",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/Casino"),
      imgUrl: "./images/trendinggames/xxtremeRoulette.jpg",
      alt: "",
      name: "XXXtreme Lightning Roulette",
      providerName: "Evolution",
    },
  },

  liveGames: {
    slide: {
      // TeenPattiSupernowa: {
      //   redirectUrl: "/casino/sn-teen-patti",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/Teen-Patti.jpg",
      //   alt: "",
      //   name: "Teen Patti",
      //   providerName: "Supernowa",
      // },
      // AkbarRomeoWalterSupernowa: {
      //   redirectUrl: "/casino/sn-akbar-romeo-walter",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/Akbar-Romeo-Walter.jpg",
      //   alt: "",
      //   name: "Akbar Romeo Walter",
      //   providerName: "Supernowa",
      // },
      // Lucky7Supernowa: {
      //   redirectUrl: "/casino/sn-lucky-7",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/Lucky-7.jpg",
      //   alt: "",
      //   name: "Lucky 7",
      //   providerName: "Supernowa",
      // },
      // Cards32Supernowa: {
      //   redirectUrl: "/casino/sn-32-cards",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/32-Cards.jpg",
      //   alt: "",
      //   name: "32 Cards",
      //   providerName: "Supernowa",
      // },
      // MuflisTeenpattiSupernowa: {
      //   redirectUrl: "/casino/sn-muflis-teenpatti",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/muflis-teenpatti.jpg",
      //   alt: "",
      //   name: "Muflis Teenpatti",
      //   providerName: "Supernowa",
      // },
      // Teenpatti2020Supernowa: {
      //   redirectUrl: "/casino/sn-teen-patti-2020",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/Teen-Patti-2020.jpg",
      //   alt: "",
      //   name: "Teen Patti 2020",
      //   providerName: "Supernowa",
      // },
      // BaccaratSupernowa: {
      //   redirectUrl: "/casino/sn-baccarat",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/baccarat.jpg",
      //   alt: "",
      //   name: "Baccarat",
      //   providerName: "Supernowa",
      // },
      // GoasAndarBaharSupernowa: {
      //   redirectUrl: "/casino/sn-goas-andar-bahar",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/goas-andar-bahar.jpg",
      //   alt: "",
      //   name: "Goa's Andar Bahar",
      //   providerName: "Supernowa",
      // },
      // ClassicAndarBaharSupernowa: {
      //   redirectUrl: "/casino/sn-classic-andar-bahar",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/classic-andar-bahar.jpg",
      //   alt: "",
      //   name: "Classic Andar Bahar",
      //   providerName: "Supernowa",
      // },
      // TeenPattiFaceOffSupernowa: {
      //   redirectUrl: "/casino/sn-teen-patti-face-off",
      //   code: "1000104",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/cardgames/teen-patti-faceoff.jpg",
      //   alt: "",
      //   name: "Teen Patti Face Off",
      //   providerName: "Supernowa",
      // },
      CasinoMarinaAndarBahar: {
        redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/cardgames/casinomarinaandarbahar.jpg",
        alt: "",
        name: "Casino Marina Andar Bahar",
        providerName: "Ezugi",
      },
      Super6Baccarat: {
        redirectUrl: "/casino/ezg-super-6-baccarat",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/cardgames/super6baccarat.jpg",
        alt: "",
        name: "Super 6 Baccarat",
        providerName: "Ezugi",
      },
      DragonTiger: {
        redirectUrl: "/casino/ezg-dragon-tiger",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/cardgames/DragonTiger.jpg",
        alt: "",
        name: "Dragon Tiger",
        providerName: "Ezugi",
      },
      NocommissionBaccarat: {
        redirectUrl: "/casino/ezg-no-commission-baccarat",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/cardgames/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarat",
        providerName: "Ezugi",
      },
      CasinoHoldem: {
        redirectUrl: "/casino/ezg-casino-holdem",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/cardgames/casinoholdem.jpg",
        alt: "",
        name: "Casino Hold'em",
        providerName: "Ezugi",
      },
      ThreeCardPoker: {
        redirectUrl: "/casino/ezgevo-three-card-poker",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/cardgames/three-card-poker.jpg",
        alt: "",
        name: "Three Card Poker",
        providerName: "Evolution",
      },
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "Live",
    key: "liveGames",
  },

  virtualGames: {
    // slide: {
    //   RNGTeenPatti: {
    //     redirectUrl: "/casino/sn-rng-teen-patti",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNG-Teen-Patti.jpg",
    //     alt: "",
    //     name: "RNG Teen Patti",
    //     providerName: "Supernowa",
    //   },
    //   RNGDragonTiger: {
    //     redirectUrl: "/casino/sn-rng-dragon-tiger",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNGDragoTiger.jpg",
    //     alt: "",
    //     name: "RNG Dragon Tiger",
    //     providerName: "Supernowa",
    //   },
    //   RNGLucky7: {
    //     redirectUrl: "/casino/sn-rng-lucky-7",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNG-Lucky-7.jpg",
    //     alt: "",
    //     name: "RNG Lucky 7",
    //     providerName: "Supernowa",
    //   },
    //   RNGWorliMatka: {
    //     redirectUrl: "/casino/sn-rng-worli-matka",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNG-Worli-Matka.jpg",
    //     alt: "",
    //     name: "RNG Worli Matka",
    //     providerName: "Supernowa",
    //   },
    //   RNG32Cards: {
    //     redirectUrl: "/casino/sn-rng-32-cards",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNG-32Cards.jpg",
    //     alt: "",
    //     name: "RNG 32 Cards",
    //     providerName: "Supernowa",
    //   },
    //   RNGAkbarRomeoWalter: {
    //     redirectUrl: "/casino/sn-rng-akbar-romeo-walter",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNG-ARW.jpg",
    //     alt: "",
    //     name: "RNG Akbar Romeo Walter	",
    //     providerName: "Supernowa",
    //   },
    //   RNGDragonTiger2020: {
    //     redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNGDragontiger2020.jpg",
    //     alt: "",
    //     name: "RNG Dragon Tiger 2020",
    //     providerName: "Supernowa",
    //   },
    //   RNG3playerTeenPatti: {
    //     redirectUrl: "/casino/sn-rng-3-player-teen-patti",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNG-3-player-3P.jpg",
    //     alt: "",
    //     name: "RNG 3 Player Teen Patti",
    //     providerName: "Supernowa",
    //   },
    //   RNGTeenPatti2020: {
    //     redirectUrl: "/casino/sn-rng-teen-patti-2020",
    //     code: "1000104",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/virtual/RNG-3patti2020.jpg",
    //     alt: "",
    //     name: "RNG Teen Patti 2020",
    //     providerName: "Supernowa",
    //   },
    //   RNGCasinoQueen: {
    //     redirectUrl: "/casino/sn-rng-casino-queen",
    //     code: "RCQ",
    //     casino: "wco",
    //     provider: "SN",
    //     homeUrl: "http://localhost:3000/Casino",
    //     imgUrl: "./images/virtual/RNGcasinoqueen.jpg",
    //     alt: "",
    //     name: "RNG Casino Queen",
    //     providerName: "Supernowa",
    //   },
    //   RNGCasinoQueen2020: {
    //     redirectUrl: "/casino/sn-rng-casino-queen-2020",
    //     code: "RCQ20",
    //     casino: "wco",
    //     provider: "SN",
    //     homeUrl: "http://localhost:3000/Casino",
    //     imgUrl: "./images/virtual/casinoqueen.jpg",
    //     alt: "",
    //     name: "RNG Casino Queen 2020",
    //     providerName: "Supernowa",
    //   },
    //   RNGJoker: {
    //     redirectUrl: "/casino/sn-rng-joker",
    //     code: "RCQ20",
    //     casino: "wco",
    //     provider: "SN",
    //     homeUrl: "http://localhost:3000/Casino",
    //     imgUrl: "./images/virtual/rngpokerjpg.jpg",
    //     alt: "",
    //     name: "RNG Joker",
    //     providerName: "Supernowa",
    //   },
    // },

    name: "Virtual",
    key: "virtualGames",
  },

  providers: {
    slide: {
      evolution: {
        code: "",
        casino: "evolution",
        redirectUrl: "/casino/evolution",
        provider: "EVO_LOBBY",
        homeUrl: "",
        imgUrl: "./images/provider/new/Evolution.png",
        alt: "",
        name: "Evolution",
        providerName: "Evolution",
      },

      ezugi: {
        redirectUrl: "/casino/ezugi",
        code: "",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/provider/new/Ezugi.png",
        alt: "",
        name: "Ezugi",
        providerName: "Ezugi",
      },
      // supernowa: {
      //   redirectUrl: "/casino/supernowa",
      //   code: "",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: "",
      //   games: {},
      //   imgUrl: "./images/provider/new/supernowa.png",
      //   alt: "",
      //   name: "Supernowa",
      //   providerName: "Supernowa",
      // },
      worldcasino: {
        redirectUrl: "/casino/worldcasino",
        code: "",
        casino: "wco",
        provider: "",
        homeUrl: "",
        games: {},
        imgUrl: "./images/provider/new/WorldCasino.png",
        alt: "",
        name: "World Casino",
        providerName: "Worldcasino",
      },
      xpg: {
        redirectUrl: "/casino/xpg",
        code: "",
        casino: "wco",
        provider: "XPG",
        homeUrl: "",
        games: {},
        imgUrl: "./images/provider/new/XPG.png",
        alt: "",
        name: "XPG",
        providerName: "XPG",
      },
      qtech: {
        redirectUrl: "/casino/qtech",
        code: "",
        casino: "wco",
        provider: "QT",
        homeUrl: "",
        games: {},
        imgUrl: "./images/provider/new/Qtech.png",
        alt: "",
        name: "Qtech",
        providerName: "Qtech",
      },
      vivo: {
        redirectUrl: "/casino/vivo",
        code: "vivo",
        casino: "vivo",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/provider/new/VivoGaming.png",
        alt: "",
        name: "Vivo",
        providerName: "Vivo",
      },
      auraGames: {
        redirectUrl: "/auraGames",
        code: "vivo",
        casino: "vivo",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/provider/new/Auragame.png",
        alt: "",
        name: "Aura Games",
        providerName: "Aura",
      },
      slots: {
        redirectUrl: "/slots",
        code: "vivo",
        casino: "vivo",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/provider/new/Slots.png",
        alt: "",
        name: "Slots",
        providerName: "Slots",
      },
      spribe: {
        redirectUrl: "/spribe",
        code: "vivo",
        casino: "vivo",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/provider/new/spribe.png",
        alt: "",
        name: "Spribe",
        providerName: "Spribe",
      },
    },

    name: "Providers",
    key: "providers",
  },
};
